

import {
  IonDatetime,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonItem,
  IonRow,
  IonCol,
  IonButton,
  IonMenuButton,
  IonRefresher,
  IonRefresherContent,
  IonText,
  IonInput,
  IonTextarea,
  IonGrid,
  IonDatetimeButton,
  IonModal,
} from "@ionic/vue";
import { computed, defineComponent, Ref, ref, watchEffect } from "vue";
import { calendarOutline, peopleOutline, } from 'ionicons/icons';
import { useStore } from "@/store";
import { useErrorBox } from '@/components/errorBox';
import { PaymentDailyCheckDetailsModel, PaymentDailyCheckModel, MoneyDetail, SalesOfDayModel } from '@/models/SalesOfTheDay';
import { createGuid } from '@/common/utils';
import moment from 'moment-timezone';
import { useFinancialStore } from "@/store/financialStore";
import { stringToHash } from '@/utils/changeTracking';

export default defineComponent({
  name: "SalesOfDay",
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonItem,
    // IonLabel,
    IonButton,
    IonDatetime,
    IonRow,
    IonCol,
    IonInput,
    IonMenuButton,
    IonRefresher,
    IonRefresherContent,
    IonText,
    IonTextarea,
    IonGrid,
    IonDatetimeButton,
    IonModal,
  },
  setup() {
    const store = useStore();
    const financial = useFinancialStore();
    const {showError} = useErrorBox();
    const momentjs = moment;    
    const m = (a: any): moment.Moment => { return momentjs(a); }

    const cash: Ref<PaymentDailyCheckDetailsModel> = ref(new PaymentDailyCheckDetailsModel());
    cash.value.name = "käteinen";
    cash.value.accountId = "3413c9da-86f3-4ba3-9a1c-5a921c622043";
    cash.value.baseBalance = 150;

    const c100 = new MoneyDetail();
    c100.count = 0;
    c100.instanceValue = 100;
    c100.name = "100€";
    c100.fillOrder = 13;    
    cash.value.details.push(c100);

    const c50 = new MoneyDetail();
    c50.count = 0;
    c50.instanceValue = 50;
    c50.name = "50€";
    c50.fillOrder = 12;
    cash.value.details.push(c50);

    const c20 = new MoneyDetail();
    c20.count = 0;
    c20.instanceValue = 20;
    c20.name = "20€";
    c20.fillOrder = 11;
    cash.value.details.push(c20);

    const c10 = new MoneyDetail();
    c10.count = 0;
    c10.instanceValue = 10;
    c10.name = "10€";
    c10.fillOrder = 10;
    cash.value.details.push(c10);

    const c5 = new MoneyDetail();
    c5.count = 0;
    c5.instanceValue = 5;
    c5.name = "5€";
    c5.fillOrder = 9;
    cash.value.details.push(c5);

    const c2 = new MoneyDetail();
    c2.count = 0;
    c2.instanceValue = 2;
    c2.name = "2€";
    c2.fillOrder = 8;
    cash.value.details.push(c2);

    const c1 = new MoneyDetail();
    c1.count = 0;
    c1.instanceValue = 1;
    c1.name = "1€";
    c1.fillOrder = 7;
    cash.value.details.push(c1);

    const c050 = new MoneyDetail();
    c050.count = 0;
    c050.instanceValue = 0.5;
    c050.name = "50c";
    c050.fillOrder = 8;
    cash.value.details.push(c050);

    const c020 = new MoneyDetail();
    c020.count = 0;
    c020.instanceValue = 0.2;
    c020.name = "20c";
    c020.fillOrder = 9;
    cash.value.details.push(c020);

    const c010 = new MoneyDetail();
    c010.count = 0;
    c010.instanceValue = 0.1;
    c010.name = "10c";
    c010.fillOrder = 10;
    cash.value.details.push(c010);

    const c005 = new MoneyDetail();
    c005.count = 0;
    c005.instanceValue = 0.05;
    c005.name = "5c";
    c005.fillOrder = 11;
    cash.value.details.push(c005);

    const sports: Ref<PaymentDailyCheckDetailsModel> = ref(new PaymentDailyCheckDetailsModel());
    sports.value.name = "liikuntasetelit";
    sports.value.accountId = "30fe7448-b59c-4d5b-ae70-c3d6cdd68768";

    const s5 = new MoneyDetail();
    s5.count = 0;
    s5.instanceValue = 5;
    s5.name = "5€";
    s5.fillOrder = 12;
    sports.value.details.push(s5);

    const s4 = new MoneyDetail();
    s4.count = 0;
    s4.instanceValue = 4;
    s4.name = "4€";
    s4.fillOrder = 13;
    sports.value.details.push(s4);

    const s1 = new MoneyDetail();
    s1.count = 0;
    s1.instanceValue = 1;
    s1.name = "1€";
    s1.fillOrder = 14;
    sports.value.details.push(s1);

    const s01 = new MoneyDetail();
    s01.count = 0;
    s01.instanceValue = 0.01;
    s01.name = "1c";
    s01.fillOrder = 15;
    sports.value.details.push(s01);

    const cards: Ref<PaymentDailyCheckDetailsModel> = ref(new PaymentDailyCheckDetailsModel());
    cards.value.name = "pankkikortit";
    cards.value.accountId = "13a487c3-6e7a-452d-8c8b-e69e1d5fbb1e";

    const ca1 = new MoneyDetail();
    ca1.count = 0;
    ca1.instanceValue = 1;
    ca1.name = "1€";
    ca1.fillOrder = 16;
    cards.value.details.push(ca1);

    const ca001 = new MoneyDetail();
    ca001.count = 0;
    ca001.instanceValue = 0.01;
    ca001.name = "1c";
    ca001.fillOrder = 17;
    cards.value.details.push(ca001);


    const cashTotal = computed(() =>{
                  let c = 0;
            cash.value.details.forEach(i => c += (i.count * i.instanceValue));
            c -= cash.value.baseBalance;
            return c;
    });

    const sportsTotal = computed(() =>{
            let c = 0;
            sports.value.details.forEach(i => c += (i.count * i.instanceValue));
            c -= sports.value.baseBalance;
            return c;
    });

    const cardsTotal = computed(() =>{
            let c = 0;
            cards.value.details.forEach(i => c += (i.count * i.instanceValue));
            c -= cards.value.baseBalance;
            return c;
    });

    const date: Ref<string> = ref(m(new Date()).utc(true).startOf('day').toISOString());
    const processing: Ref<boolean> = ref(true);
    const justRawList: Ref<Array<SalesOfDayModel>> = ref([]);

    const id: Ref<string|undefined> = ref(undefined);
    const comments: Ref<string> = ref("");

    const organization = computed(() => store.state.organization);

    const hasRoleInstructor = computed(() => { 
      return store.getters.userInfo.roles?.indexOf("Instructor") >= 0 ||
             store.getters.userInfo.roles?.indexOf("Merchant") >= 0 ||
             store.getters.userInfo.roles?.indexOf("Admin") >= 0;});

    const loadDetails = (from: PaymentDailyCheckDetailsModel) => {
      if(from.accountId == cash.value.accountId){
        from.details.forEach(d=>{
          const e = cash.value.details.find(i=>i.instanceValue == d.instanceValue);
          if(e){ e.count = d.count; }
        })
      } else if(from.accountId == cards.value.accountId){
        from.details.forEach(d=>{
          const e = cards.value.details.find(i=>i.instanceValue == d.instanceValue);
          if(e){ e.count = d.count; }
        })
      } else if(from.accountId == sports.value.accountId){
        from.details.forEach(d=>{
          const e = sports.value.details.find(i=>i.instanceValue == d.instanceValue);
          if(e){ e.count = d.count; }
        })
      } 
    }

    const loadMoney =  async () => {
      processing.value = true;
      try {
        const from = m(date.value).utc(false).startOf('day').toDate();
        const to = m(date.value).utc(false).endOf('day').toDate();        
        const r = await financial.getSalesOfDays("kassa",from,to);
        if(r) justRawList.value = r;

        cash.value.details.forEach(i=>i.count = 0);
        sports.value.details.forEach(i=>i.count = 0);
        cards.value.details.forEach(i=>i.count = 0);
        id.value = undefined;

        const r2 = await financial.getPaymentDailyCheck("kassa", m(date.value).utc(false).toDate());

        r2.details.forEach(d=>loadDetails(d));
        id.value = r2.id;
        comments.value = r2.comments ?? "";


        if(r) justRawList.value = r;

      } catch (err) {
        showError(err as string,true);
      } finally {
        savedHash.value = hashComponentState.value;
        processing.value = false;
      }
    };

    const save =  async () => {
      processing.value = true;
      try {

      const model = new PaymentDailyCheckModel();
      if(id.value){
        model.id = id.value;
      } else {
        model.id = createGuid();
      }

      model.checkTime = new Date();
      model.date = m(date.value).utc(false).toDate();
      model.comments = comments.value;
      model.source = "kassa";
      model.organizationId = organization.value.id;

      model.details = [];
      model.details.push(cash.value);
      model.details.push(cards.value);
      model.details.push(sports.value);

      await financial.updatePaymentDailyCheck(model);

      savedHash.value = hashComponentState.value;

      } catch (err) {
        showError(err as string,true);
      } finally {
        processing.value = false;
      }
    }
    
    //#region isDirty check for relative simple components
    const savedHash: Ref<number> = ref(0);
    const hashComponentState = computed(() => {
      return stringToHash(JSON.stringify({
        id: id.value,
        date: date.value,
        comments: comments.value,
        cash: cash.value,
        sports: sports.value,
        cards: cards.value,
        organisation: organization.value,
      }));
    });

    const isDirty = computed(() => {
      if (savedHash.value === 0)
        return false;
      return savedHash.value !== hashComponentState.value;
    });
    //#endregion

    watchEffect( () => {
      if(!store.state.organization.id || store.state.organization.id === ""){
        showError("Toimipaikkaa ei löydy.",true);
      }

      if(!date.value){
        showError("Päivä tarvitaan.",true);
      }

      loadMoney();
    });

    const selectDate = (message: CustomEvent) => {
      const day = m(message.detail.value).utc(true).startOf('day');
      date.value = day.toISOString();
    };

    const refreshNow = (item: any) => {
      loadMoney();
      item.target.complete();
    };
  
    return {
      organization,
      selectDate,
      calendarOutline,
      date,
      hasRoleInstructor,
      processing,
      refreshNow,
      peopleOutline,
      justRawList,
      cash,cashTotal,
      sports,sportsTotal,
      cards,cardsTotal,
      save,
      momentjs,
      comments,
      isDirty,
    };
  },
});
