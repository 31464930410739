import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_menu_button, { menu: "main-menu" })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("Raportit")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_ctx.allSqlRequests.length == 0)
        ? (_openBlock(), _createBlock(_component_ion_content, {
            key: 0,
            class: "ion-padding"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Ladataan ")
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.allSqlRequests.length > 0)
        ? (_openBlock(), _createBlock(_component_ion_content, {
            key: 1,
            class: "ion-padding"
          }, {
            default: _withCtx(() => [
              (_ctx.saveReportLink)
                ? (_openBlock(), _createBlock(_component_ion_button, {
                    key: 0,
                    href: _ctx.saveReportLink,
                    download: _ctx.reportName + '.xlsx'
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Tallenna raportti " + _toDisplayString(_ctx.reportName), 1)
                    ]),
                    _: 1
                  }, 8, ["href", "download"]))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allSqlRequests, (product) => {
                return (_openBlock(), _createBlock(_component_ion_item, {
                  key: product.id
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_button, {
                      slot: "end",
                      onClick: ($event: any) => (_ctx.downloadFile(product))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Luo raportti")
                      ]),
                      _: 2
                    }, 1032, ["onClick"]),
                    _createVNode(_component_ion_text, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(product.title), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}