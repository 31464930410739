import { EventInstanceViewModel } from "./EventInstanceViewModel";
import { ReservationViewModel } from "./ReservationViewModel";

export class NewVisitModel {
    eventInstance: EventInstanceViewModel | undefined;
    id: any;
    quantity: number;
    ticketId: string;
    familyMember: string;
    reservation: string | undefined;

    constructor() {
        this.quantity = 0;
        this.familyMember = "";
        this.ticketId = "";
    }
}

export class NewVisitViewModel {
    eventId: string;
    id: any;
    quantity: number;
    ticketId: string;
    memberId: string;
    reservation: string | undefined;
    visitTime: Date|undefined;

    constructor() {
        this.quantity = 0;
        this.eventId = "";
        this.ticketId = "";
        this.memberId = "";
    }

    fromResponse = (from: NewVisitViewModel) => {
        this.id = from.id;
        this.eventId = from.eventId;
        this.quantity = from.quantity;
        this.ticketId = from.ticketId;
        this.memberId = from.memberId;
        this.visitTime = new Date(from.visitTime as any);
        this.reservation = from.reservation;
        return this;
    }
}

export class EventVisitDetailedInfo {
    id: string;
    time: Date;
    familyMemberName: string;
    familyMemberId: string;
    ticketType: string;
    ticketId: string;

    constructor(){
        this.id = "";
        this.time = new Date();
        this.ticketType = "";
        this.ticketId = "";
        this.familyMemberName = "";
        this.familyMemberId = "";
    }

    fromResponse = (from: EventVisitDetailedInfo) => {
        this.id = from.id;
        this.familyMemberName = from.familyMemberName;
        this.familyMemberId = from.familyMemberId;
        this.ticketType = from.ticketType;
        this.ticketId = from.ticketId;
        this.time = new Date(from.time as any);
        return this;
    }
}

export class SeasonTicketDetailedInfo {
    familyMemberName: string;
    familyMemberId: string;
    ticketType: string;
    ticketId: string;
    ticketActive: boolean;

    constructor(){
        this.ticketType = "";
        this.ticketId = "";
        this.familyMemberName = "";
        this.familyMemberId = "";
        this.ticketActive = false;
    }

    fromResponse = (from: SeasonTicketDetailedInfo) => {
        this.familyMemberName = from.familyMemberName;
        this.familyMemberId = from.familyMemberId;
        this.ticketType = from.ticketType;
        this.ticketId = from.ticketId;
        this.ticketActive = from.ticketActive;
        return this;
    }
}



export class EventVisitsDetailedInfo {
    id: string;
    templateTitle: string;
    templateId: string;
    eventType: string;
    begins: Date;
    ends: Date;

    reservations: Array<ReservationViewModel>;
    seasonTickets: Array<SeasonTicketDetailedInfo>;
    visits: Array<EventVisitDetailedInfo>;

    constructor(){
        this.id = "";
        this.templateId = "";
        this.templateTitle = "";
        this.begins = new Date();
        this.ends = new Date();
        this.eventType = "";
        this.reservations = [];
        this.visits = [];
        this.seasonTickets = [];
    }

    fromResponse = (from: EventVisitsDetailedInfo) => {
        
        this.id = from.id;
        this.templateTitle = from.templateTitle;
        this.templateId = from.templateId;
        this.eventType = from.eventType;
        this.begins = new Date(from.begins as any);
        this.ends = new Date(from.ends as any);

        from.reservations.forEach(i=>{
             this.reservations.push((new ReservationViewModel).fromResponse(i));
        },this);
        this.reservations.sort((i,j)=>(i.created ?? new Date()).getTime()-(j.created?? new Date()).getTime());

        from.visits.forEach(i=>{
            this.visits.push((new EventVisitDetailedInfo).fromResponse(i));
        },this);
        this.visits.sort((i,j)=>(i.time ?? new Date()).getTime()-(j.time?? new Date()).getTime());

        from.seasonTickets.forEach(i=>{
            this.seasonTickets.push((new SeasonTicketDetailedInfo).fromResponse(i));
        },this);
        this.seasonTickets.sort((i,j)=>i.familyMemberName.localeCompare(j.familyMemberName));

        return this;
    }

}