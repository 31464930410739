import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_datetime_button = _resolveComponent("ion-datetime-button")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_datetime = _resolveComponent("ion-datetime")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_back_button, { defaultHref: "/shoppingCartsPage" }),
                  _createVNode(_component_ion_menu_button, { menu: "main-menu" })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("Korjaa kassaa")
                ]),
                _: 1
              }),
              _createVNode(_component_ion_buttons, { slot: "end" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    onClick: _ctx.save,
                    color: "danger"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, { icon: _ctx.saveOutline }, null, 8, ["icon"]),
                      _createTextVNode(" Tallenna ")
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_ion_datetime_button, {
                    color: "primary",
                    datetime: "datetime"
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_modal, { "keep-contents-mounted": true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_datetime, {
            id: "datetime",
            presentation: "date",
            "first-day-of-week": 1,
            value: _ctx.date.toISOString(),
            onIonChange: _ctx.selectDate
          }, null, 8, ["value", "onIonChange"])
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { class: "ion-padding" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_title, null, {
            default: _withCtx(() => [
              _createTextVNode("Korjattavat summat")
            ]),
            _: 1
          }),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.amounts, (amount) => {
            return (_openBlock(), _createBlock(_component_ion_item, {
              key: amount.account.id
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_input, {
                  modelValue: amount.amount,
                  "onUpdate:modelValue": ($event: any) => ((amount.amount) = $event),
                  label: amount.account.name,
                  labelPlacement: "stacked",
                  inputmode: "decimal"
                }, null, 8, ["modelValue", "onUpdate:modelValue", "label"])
              ]),
              _: 2
            }, 1024))
          }), 128)),
          _createVNode(_component_ion_title, { style: {"margin-top":"20px"} }, {
            default: _withCtx(() => [
              _createTextVNode("Kommentit")
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_textarea, {
                modelValue: _ctx.comments,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.comments) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}