
import { IonLabel } from '@ionic/vue';
import { defineComponent, onMounted, ref, Ref } from 'vue';
import { LocationViewModel } from '@/models/LocationViewModel';
import { useCalendarStore } from '@/store/calendarStore';

export default defineComponent({
  name: 'LocationItem',
  props: {
    id: { type: String },
  },
  components:{IonLabel},
  setup(props) {
    const calendarStore = useCalendarStore();
    const fullDetails: Ref<LocationViewModel|undefined> = ref(undefined);

    onMounted(() => {
      if(fullDetails.value === undefined) {
        calendarStore.getLocation(props.id as string)
        .then((result) => {
          fullDetails.value = result;
        });
      }
    });

      return {        
        fullDetails
      }
  }
});
