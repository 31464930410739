
import { IonLabel } from '@ionic/vue';
import { defineComponent, onMounted, ref, Ref } from 'vue';

export default defineComponent({
  name: 'InstructorItem',
  props: {
    data: { type: String },
  },
  components:{IonLabel},
  setup(props) {

  //const store = useStore();
  const name: Ref<string|undefined> = ref(undefined);
  const id: Ref<string|undefined> = ref(undefined);

onMounted(() => {
  if(props.data && props.data.length > 1){
//    debugger;    
    const m = props.data.match(/^([^(]+)\((.+)\)$/);
    if(m && m.length === 3)
    {
      name.value = m[1];
      id.value = m[2];
    }
  }
});

      return {        
        name
      }
  }
});
