import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_menu_button, { menu: "main-menu" })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("Infosivut")
                ]),
                _: 1
              }),
              _createVNode(_component_ion_buttons, { slot: "end" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    color: "primary",
                    size: "default",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.router.push(`/editInfoPage/`)))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, { icon: _ctx.addOutline }, null, 8, ["icon"]),
                      _createVNode(_component_ion_text, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Uusi")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_ctx.allInfoPages.length==0)
        ? (_openBlock(), _createBlock(_component_ion_content, {
            key: 0,
            class: "ion-padding"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Ladataan ")
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.allInfoPages.length>0)
        ? (_openBlock(), _createBlock(_component_ion_content, {
            key: 1,
            class: "ion-padding"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_grid, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_col, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_text, null, {
                            default: _withCtx(() => [
                              _createTextVNode("#")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_col, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_text, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Koodi")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_col, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_text, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Otsikko")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_col)
                    ]),
                    _: 1
                  }),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allInfoPages, (infoPage) => {
                    return (_openBlock(), _createBlock(_component_ion_row, {
                      key: infoPage.id
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_col, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_button, {
                              onClick: ($event: any) => (_ctx.router.push(`/editInfoPage/${infoPage.id}`))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, { icon: _ctx.pencil }, null, 8, ["icon"]),
                                _createTextVNode(" muokkaa")
                              ]),
                              _: 2
                            }, 1032, ["onClick"])
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_ion_col, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_text, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(infoPage.code), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_ion_col, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_text, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(infoPage.title), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_ion_col, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_button, {
                              color: "danger",
                              onClick: ($event: any) => (_ctx.showRemoveDialog(infoPage))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, { icon: _ctx.trashOutline }, null, 8, ["icon"]),
                                _createTextVNode(" poista")
                              ]),
                              _: 2
                            }, 1032, ["onClick"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}