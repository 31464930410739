export class InfoPageListModel {
    id: string | undefined;
    code: string;
    title: string;

    constructor() {
        this.code = "";
        this.title = "";
    }
}

export class InfoPageModel extends InfoPageListModel
{
    html: string;
    constructor() {
        super();
        this.html = "";
    }
}

export class InfoPageExtendedModel extends InfoPageModel
{
    html: string;
    selected: boolean;

    constructor(page: InfoPageModel) {
        super();
        this.id = page.id;
        this.code = page.code;
        this.title = page.title;
        this.html = page.html;
        this.selected = false;
    }
}

export class InfoPageListReponse extends Array<InfoPageListModel>
{

}