
import {
  IonBackButton,
  IonButtons,
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonItem,
  IonLabel,
  IonCheckbox,
  IonIcon,
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  loadingController,
} from "@ionic/vue";
import { computed, defineComponent, onMounted } from "vue";
import { useRouter } from "vue-router";
import { ActionTypes,  useStore } from "../store";
import { trashOutline } from "ionicons/icons";
import { useErrorBox } from "@/components/errorBox";
import { ShoppingCartRow } from "@/models/ShoppingCart";
import { useCheckoutUtils } from "@/common/CheckoutUtils";

export default defineComponent({
  name: "CheckOut",
  props:{ back: { type: Boolean, default: false} },
  components: {
    IonBackButton,
    IonButtons,
    IonButton,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonItem,
    IonLabel,
    IonToolbar,
    IonCheckbox,
    IonIcon,
    IonText,
    IonInput,
    IonGrid,
    IonRow,
    IonCol,
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const {showError} = useErrorBox();
    const {refreshCart, processing} = useCheckoutUtils();
    const shoppingCart = computed(() =>  store.getters.cart);
    const agreed = computed(() =>  store.getters.cart?.agreed != undefined);

  const loading = async () => {
    const loading = await loadingController
      .create({
        message: 'Hetki...',
        duration: undefined,
      });

    await loading.present();
    return loading;
  }


    onMounted( async () => {
      await refreshCart();
      });
      
      const continueCheckout = () => {
        router.replace('/checkout2/');
      };

      const agreeNow = (a: any) => {
        if(!shoppingCart.value)
          return;

        if(a===false){
          shoppingCart.value.agreed = undefined;
        } else if(a===true){
          shoppingCart.value.agreed = new Date();
        }
      };

      const removeRow = async (row: ShoppingCartRow) => {
        const l = await loading();
        try {
          await store.dispatch(ActionTypes.REMOVE_ROW,row);
        } catch (err){
          showError(err as string, true);
        } finally {
          await l.dismiss();
        }

        if(!shoppingCart.value?.rows || shoppingCart.value?.rows.length === 0){
          const e =  await showError("Ostoskori on tyhjä. Palataan takaisin.",false);
          await e.onDidDismiss()

          if(props.back)
            router.back();
          else
            router.replace('/main/home');
        }
      };

    return {
      onMounted,
      router,
      continueCheckout,
      shoppingCart,
      removeRow,
      agreeNow,
      processing,trashOutline,
      agreed,
    };
  },
});

