
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonToolbar,
  IonList,
  IonButton,
  IonMenuButton,
  loadingController,
} from "@ionic/vue";
import { computed, defineComponent, onMounted, onUnmounted, Ref, ref, watch, } from "vue";
import { useRouter } from "vue-router";
import { personOutline, personAddOutline, logOutOutline, arrowBack } from "ionicons/icons";
import EventInstanceButton from './EventInstanceButton.vue'
import { useStore, ActionTypes } from "../store";
import { EventInstanceViewModel } from "@/models/EventInstanceViewModel";
import { NewVisitViewModel } from "@/models/NewVisitModel";
import { FamilyMemberDetailedViewModel, } from "@/models/UserDetailedViewModel";

import { useErrorBox } from "@/components/errorBox";
import { createGuid } from "@/common/utils";
import { TicketInfo } from "@/models/Ticket";
import TicketButton from "@/components/TicketButton.vue";
import { ReservationViewModel } from "@/models/ReservationViewModel";
import momentjs from 'moment-timezone'

export class ActiveTickets{
  member: FamilyMemberDetailedViewModel | undefined;
  ticket: TicketInfo | undefined;
}

export default defineComponent({
  name: "CheckIn",
  components: {
    TicketButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonList,
    IonIcon,
    IonButton,
    EventInstanceButton,
    IonMenuButton,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const {showError} = useErrorBox();

    const id = parseInt(router.currentRoute.value.params["id"]?.toString() ?? -1);
    const cardreader = router.currentRoute.value.params["cardreader"]?.toString();

    const selectedTicket: Ref<TicketInfo|null> = ref(null);

    const activeTicketsNew: Ref<Array<TicketInfo>> = ref([]);
    const activeEventsNew: Ref<Array<EventInstanceViewModel>> = ref([]);

    const activeReservationsNew: Ref<Array<ReservationViewModel>> = ref([]);

    const organization = computed(() => store.state.organization);
    const user = computed(() => store.state.user);

    let inputTimeoutID: number|undefined = undefined;
    const timeout = 30000;

    const moveBack = ()=> {
      if(cardreader)
        router.replace('/cardread');
    };

    const loading = async () => {
      const loading = await loadingController
        .create({
          message: 'Hetki...',
          duration: undefined,
        });

      await loading.present();
      return loading;
    }

    const selectTicket = async (value: TicketInfo|undefined) => {      
      if (!value)
        return;
        
      if(inputTimeoutID){ clearTimeout(inputTimeoutID); }
      inputTimeoutID = setTimeout(moveBack,timeout);

      const l = await loading();

      selectedTicket.value = value;

      if(activeReservationsNew.value.findIndex(i=>i.ticketBased)>=0){
        const list = activeReservationsNew.value;
        activeReservationsNew.value = [];
        list.forEach(i=>{
          if(!i.familyMemberId) {
            i.familyMemberId = selectedTicket.value?.memberId;
            i.ticketId = selectedTicket.value?.id;
          }
        });
        activeReservationsNew.value = list;
      }

      try {
        const p = {userId: id, ticketId: value.id, organizationId: store.getters.organisation.id};
        const response = await store.dispatch(ActionTypes.GET_EVENTS_OF_TICKET,p);
        activeEventsNew.value = response;
      } catch {
        activeEventsNew.value = [];
      } finally {
        l.dismiss();
      }
    };

    const updateTickets = async () => {      
      if(inputTimeoutID){ clearTimeout(inputTimeoutID); }
      inputTimeoutID = setTimeout(moveBack,timeout);

      const l = await loading();
      try {
        const r4 = await store.dispatch(ActionTypes.GET_TICKETS_OF_USER,{userId:id,organization:organization.value.id});
        activeTicketsNew.value = r4;

        const today = momentjs().startOf('day').toDate();
        const tomorrow = momentjs().add(3,'hours').endOf('days').toDate();
                
        if(!activeTicketsNew.value || activeTicketsNew.value.length === 0)
            throw 'Ei käytettävissä olevia kortteja tai jäsenyyksiä.';

        selectTicket(activeTicketsNew.value[0]);

        const r5 = await store.dispatch(ActionTypes.LIST_RESERVATIONS,{userId:id,from:today,to:tomorrow});
        r5.forEach(i=>{
          if(!i.familyMemberId) {
            i.familyMemberId = activeTicketsNew.value[0].memberId;
            i.ticketId = activeTicketsNew.value[0].id;
            i.ticketBased = true;
          }          
        });
        activeReservationsNew.value = r5;

      } catch(err: any) {
        if(err?.message){
          showError(err.message, true, 50000 , 'loginAlert');
        } else {
          showError(err as string, true, 50000 , 'loginAlert'); 
        }
      } finally {
        l.dismiss();
      }
    };

    watch(
        () => store.state.organization.id, async (id, _oldId) => {
          if(!id)
            return;
        await updateTickets();
    });

    onMounted(async () => { 
      await updateTickets(); 
    });

    onUnmounted(() => { 
      if(inputTimeoutID) { 
        clearTimeout(inputTimeoutID); 
        } 
    });

    const selectEvent =  async (value: EventInstanceViewModel|undefined) => {
      if(inputTimeoutID){ clearTimeout(inputTimeoutID); }
      inputTimeoutID = setTimeout(moveBack,timeout);

      const l = await loading();

      try {  
        const m = new NewVisitViewModel();
        m.id = createGuid();
        m.quantity = 1;
        m.ticketId = selectedTicket.value?.id ?? "";
        m.memberId = selectedTicket.value?.memberId ?? "";
        m.eventId = value?.id ?? "";
        m.reservation= undefined;
  
        await store.dispatch(ActionTypes.ADD_VISIT,m);
        
        const box = await showError("Kirjautuminen onnistui. Hyvää treeniä!", false, 3000, 'loginAlert');
  
        l.dismiss();
        await box.onDidDismiss();  
  
        if(cardreader) {
          moveBack();
        } else {  
          if(id == user.value.id){
            await store.dispatch(ActionTypes.REFRESH_VISITS, undefined);
            await store.dispatch(ActionTypes.REFRESH_RESERVATIONS, undefined);
          }
          selectedTicket.value = null;
          activeEventsNew.value = [];            
          await updateTickets();
        }
      } catch (err) {

        l.dismiss();
        if(err)
          showError(err as any, true, 5000, 'loginAlert');
      } 
    };

    const selectReservationEvent = async (value: {reservation: ReservationViewModel; instance: EventInstanceViewModel; ticketId: string}|undefined) => {
      if(inputTimeoutID){ clearTimeout(inputTimeoutID); }
      inputTimeoutID = setTimeout(moveBack,timeout);

      if(!value)
        return;

      const l = await loading();

      try {  
        const m = new NewVisitViewModel();
        m.id = createGuid();
        m.quantity = value.reservation.quantity;
        m.ticketId = value.ticketId;
        m.memberId = value.reservation.familyMemberId ?? "";
        m.eventId = value.instance.id;
        m.reservation= undefined;
  
        await store.dispatch(ActionTypes.ADD_VISIT,m);
        
        const box = await showError("Kirjautuminen onnistui. Hyvää treeniä!",false, 5000, 'loginAlert');
  
        l.dismiss();
        await box.onDidDismiss();  
  
        if(!id)
          await store.dispatch(ActionTypes.REFRESH_VISITS, undefined);
  
        selectedTicket.value = null;
        activeEventsNew.value = [];

      } catch (err) {
        l.dismiss();
        if(err)
          showError(err as any, true, 5000, 'loginAlert');
      } 
    }

    return {
      router,
      personOutline,
      personAddOutline,
      selectTicket,
      selectEvent,
      selectReservationEvent,
      selectedTicket,
      activeTicketsNew,
      activeEventsNew,
      organization,
      activeReservationsNew,
      moveBack,
      cardreader,
      logOutOutline,arrowBack,
    };
  }
});
