import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-57664088"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!

  return (_ctx.fullDetails)
    ? (_openBlock(), _createBlock(_component_ion_label, { key: 0 }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.fullDetails?.title), 1)
        ]),
        _: 1
      }))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        (!_ctx.fullDetails)
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, "..."))
          : _createCommentVNode("", true)
      ], 64))
}