export class SalesOfDayModel{
    date: Date | undefined;
    amount: number | undefined;
    source: string;
    account: string;
    accountId: string| undefined;
    transactions: number| undefined;

    constructor(){
        this.source = "";
        this.account = "";
    }

    fromResponse = (from: SalesOfDayModel) => {
        if(from.date){ this.date = new Date(from.date);}
        this.amount = from.amount;
        this.source = from.source;
        this.account = from.account;
        this.accountId = from.accountId;
        this.transactions = from.transactions;
        return this;
    }
}
export class MoneyDetail{
    name: string;
    count: number;
    fillOrder: number;
    instanceValue: number;
    constructor(){
        this.name = "";
        this.count = 0;
        this.fillOrder = 0;
        this.instanceValue = 0;
    }
}

export class PaymentDailyCheckDetailsModel{
    accountId: string;
    change: number;
    baseBalance: number;
    details: Array<MoneyDetail>
    name: string;

    constructor(){
        this.accountId = "";
        this.change = 0;
        this.baseBalance = 0;
        this.details = [];
        this.name = "";
    }
}


export class PaymentDailyCheckModel{
    id: string;
    checkTime: Date;
    date: Date;
    userId: number;
    comments: string;
    source: string;
    organizationId: string|undefined;
    details: Array<PaymentDailyCheckDetailsModel>;

    constructor(){
        this.id = "";
        this.checkTime = new Date();
        this.date = new Date();
        this.userId = 0;
        this.comments = "";
        this.source = "";
        this.details = [];
    }
}