
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonInput,
  IonItem,
  loadingController,
} from "@ionic/vue";
import { computed, defineComponent, onMounted, onUnmounted, Ref, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { personOutline, personAddOutline, logInOutline} from "ionicons/icons";
import { useStore, ActionTypes, } from "../store";
import { useErrorBox } from "@/components/errorBox";

export default defineComponent({
  name: "CardRead",
  components: {
    IonContent,
    IonHeader,
    IonItem,
    IonPage,
    IonTitle,
    IonToolbar,
    IonInput,
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const processing: Ref<boolean> = ref(false);
    const {showError} = useErrorBox();
    const keyStr: Ref<string> = ref("");
    const avain: any = ref(null)

    const hasRoleCheckin = computed(() => { return store.getters.userInfo.roles?.indexOf("CheckinOnly") >= 0;});


    const loading = async (d: number | undefined) => {
      const loading = await loadingController
        .create({
          message: 'Hetki...',
          duration: d,
        });

      await loading.present();
      return loading;
    }

    let focusTimeoutID: number|undefined = undefined;
    let inputTimeoutID: number|undefined = undefined;

    const focus = ()=> {
         keyStr.value = "";
         avain.value?.$el?.setFocus();
         focusTimeoutID = setTimeout(focus,3000);
    };

    const tryInput = async () => {
      const l = await loading(undefined);
      try {
        const str = keyStr.value;
        keyStr.value = "";
        avain.value?.$el?.setFocus();
        focusTimeoutID = setTimeout(focus,3000);

        const userId = await store.dispatch(ActionTypes.USER_OF_KEY, str);

        if(userId>0) {
          router.replace('/checkin/' + userId + '/true');
        } else {
          showError("Avainta ei löytynyt",true);
        }

      } catch (reason) {
        showError(reason as string,true);
      } finally {
        l.dismiss();
      }
    };

    onMounted( async () => {
      await store.dispatch(ActionTypes.CHECK_AUTH, undefined);
      focusTimeoutID = setTimeout(focus,3000);
    });

    onUnmounted( async () =>{
      if(focusTimeoutID){ clearTimeout(focusTimeoutID); }
      if(inputTimeoutID){ clearTimeout(inputTimeoutID); }
    });

    watch(
        () => keyStr.value, (id, _oldId) => {
          if(!id)
            return;

            if(focusTimeoutID){clearTimeout(focusTimeoutID);}
            if(inputTimeoutID){clearTimeout(inputTimeoutID);}
            inputTimeoutID = setTimeout(tryInput,500);
      });

    return {
      router,
      logInOutline,personOutline,personAddOutline,
      processing,
      keyStr,
      avain,
      hasRoleCheckin,
    };
  },
});
