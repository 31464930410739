
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonLabel,
  IonButton,
  IonMenuButton,
} from "@ionic/vue";
import { defineComponent, onMounted, Ref, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore, ActionTypes } from "../store";
import { EventDescription } from "@/models/EventDescription";
import { OrganizationInfo } from "@/models/Organization";
import * as moment from 'moment-timezone';
import { useErrorBox } from "@/components/errorBox";
import { useCalendarStore } from "@/store/calendarStore";
import { EventCategoryViewModel } from "@/models/EventCategoryViewModel";

export default defineComponent({
  name: "ListEvents",
  data() {
    return {
      searchTerm: "",
      processing: false,
    };
  },
  components: {
     IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
  IonList,
  IonItem,
  IonLabel,
  IonButton,
  IonMenuButton,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const calendarStore = useCalendarStore();
    const {showError} = useErrorBox();

    const categoryId: Ref<string|undefined> = ref(undefined);
    
    const eventTemplates: Ref<Array<EventDescription>> = ref([]);
    const organization: Ref<OrganizationInfo> = ref(store.state.organization);

    const category = ref<EventCategoryViewModel|undefined>(undefined);
    const momentjs: any = moment;
    moment.locale("fi");


    onMounted(async() => {
      categoryId.value = router.currentRoute.value.params["id"] as string;

      category.value = await calendarStore.getCategory(categoryId.value);

      store.dispatch(ActionTypes.GET_EVENT_TEMPLATES,{organizationId: store.getters.organisation.id,
        locationId:undefined,
        categoryId:categoryId.value})
        .then((response)=>{
          if(response){
            eventTemplates.value = response;
      }
    }).catch((err)=>{
      showError(err,true);      
    });  
 });
   
    const registerToEvent = (item: any) => {
        if(item){
            router.push(`/RegisterTo/${item.id}`);
      }
    };

    return {
      router,
      eventTemplates,
      registerToEvent,
      organization,
      momentjs,
      category,
    };
  },
});
