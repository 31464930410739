import { alertController } from '@ionic/vue';
export function useErrorBox () {
//   const articles = ref([])
//   const searchParameters = ref([])

//   onMounted(() => {
//     this.articles = ArticlesAPI.loadArticles()
//   })

//   const searchArticles = (id) => {
//     return articles.filter(() => {
//       // some search code
//     })
//   }

    const showError = async (message: any, isError = false, timeOut = 20000, cssClass = 'defaultAlert') => {

      let messageText = "undefined";
      if(typeof message === 'string'){
        messageText = message;
      } else if(typeof message === 'object'){
        if(message instanceof Error){
          messageText = message.message;
        } else {
          messageText = JSON.stringify(message);
        }
      } else {
        messageText = message.toString();
      }

      const alert = await alertController
        .create({
          header: isError ? "Virhe" : "Huom",
          cssClass: cssClass,
          message: messageText,
          buttons: [{text:"OK", cssClass: cssClass + 'Ok'}],
        });
        await alert.present();

        const closeTimeout = setTimeout(() => alert.dismiss(), timeOut);
        alert.onDidDismiss().then(_i=>{ if(closeTimeout){ clearTimeout(closeTimeout);}});
      
        return alert;
    };

  return {
    showError
  }
}