
import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/vue';
import { useStore, } from "@/store";
import { defineComponent, watch } from 'vue';
import MenuComponent from './components/menu.vue'
import { useUserStore } from './store/userStore';
export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    IonSplitPane,
    MenuComponent,
  },
  setup() {
    
    const store = useStore();
    const userStore = useUserStore();
    // Add or remove the "dark" class on the document body
    const toggleDarkTheme = (shouldAdd: boolean) => {
      if(store.state.theme === 'dark') {
        document.body.classList.toggle('dark', true);
      } else if(store.state.theme === 'light') {
        document.body.classList.toggle('dark', false);
      } else {
        document.body.classList.toggle('dark', shouldAdd);
      }
    };



    watch(() => store.state.theme, (_newTheme, _oldTheme) => {
      const d = window.matchMedia('(prefers-color-scheme: dark)');
      toggleDarkTheme(d.matches);
    });

    // Use matchMedia to check the user preference
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    toggleDarkTheme(prefersDark.matches);

    // Listen for changes to the prefers-color-scheme media query
    prefersDark.addEventListener('change', (mediaQuery) => toggleDarkTheme(mediaQuery.matches));


    // Listen for changes in the visibility of the document
    document.addEventListener("visibilitychange", async() => userStore.visibilityChanged(document.hidden));

    return {};
  },
});

//<ion-vue-router id="main"></ion-vue-router>
