export class ShoppingCartIndexPayment {
    account: string;
    accountId: string|undefined;
    received: string|undefined;

    constructor(){
        this.account = "";
    }

    fromResponse = (from: ShoppingCartIndexPayment) => {
        this.account = from.account;
        this.accountId = from.accountId;
        this.received = from.received;
        return this;
    }
}

export class ShoppingCartIndex2 {
    id: string;
    userId: number|undefined;
    state: string;
    created: Date;
    comments: string;
    number: number|undefined;
    type: string;
    total: number|undefined;
    lines: number|undefined;

    payments: Array<ShoppingCartIndexPayment>;

    constructor() {
        this.id = "";
        this.state = "";
        this.created = new Date();
        this.comments = "";
        this.type = "";
        this.payments = [];
    }

    fromResponse = (from: ShoppingCartIndex2) => {
        this.id = from.id;
        this.userId = from.userId;
        this.state = from.state;
        this.comments = from.comments;
        this.number = from.number;
        this.type = from.type;
        this.total = from.total;
        this.lines = from.lines;
        this.created = new Date (from.created as any);

        from.payments.forEach(i=>{
            const r = (new ShoppingCartIndexPayment).fromResponse(i);
            this.payments.push(r);
        },this);

        return this;
    }

}



// public class ShoppingCartIndexPayment
// {
//     public string Account { get; set; }
//     public Guid? AccountId { get; set; }
//     public DateTime? Received { get; set; }
// }

// public class ShoppingCartIndex2
// {

//     public decimal? Total { get; set; }
//     public int? Lines { get; set; }

//     public List<ShoppingCartIndexPayment> Payments { get; set; }
// }