

import { createGesture, GestureDetail } from "@ionic/vue";
import { defineComponent, onMounted, Ref, ref } from "vue";
import { emitter } from "@/components/useEvents";

export default defineComponent({
  name: 'AddDraggable',
  props: {
    destination: { type: String, required: true},
    class: { type: String, required: false},
    dropData: { required: true}
  },
  setup(props) {

    const root: Ref<HTMLElement|undefined>  = ref(undefined); 
    const classString: Ref<string>  = ref("");

    const onStart = (_ev: GestureDetail) => {
      if(!root.value) return;
      root.value.style.transition = 'none';
      root.value.style.opacity = '0.7';
      root.value.style.zIndex = '999999';
    };

    const onMove = (ev: GestureDetail) => {
      if(!root.value) return;
      root.value.style.transform = `translate(${ev.deltaX}px, ${ev.deltaY}px)`;
      emitter.emit("onElementMoving",{ev:ev, data: props.dropData, destination: props.destination});
    };

    const onEnd = (ev: GestureDetail) => {

      if(!root.value) return;
      root.value.style.transition = '.3s ease-out';
      root.value.style.transform = `translate(0, 0)`;
      root.value.style.zIndex = 'inherit';
      root.value.style.opacity = '1';
      emitter.emit("onElementDropping",{ev:ev, data: props.dropData, destination: props.destination});
    };

    onMounted( async () => {

      if(props.class)
        classString.value = props.class;

      if(!root.value) return;
      const dragGesture = createGesture({
                    gestureName: "draggable",
                    el: root.value,
                    threshold: 0, 
                    onStart: onStart,
                    onMove: onMove,
                    onEnd: onEnd
             });

      dragGesture.enable(true);
    });


    return {
      root,
      classString
    };
  }
});
