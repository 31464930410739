
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
} from "@ionic/vue";
import { defineComponent, onMounted, Ref, ref } from "vue";
import { useRouter } from "vue-router";
import { ActionTypes, useStore } from "../store";

export default defineComponent({
  name: "CheckOutPaymentOk",
  components: {
    IonBackButton,
    IonButtons,
    IonHeader,
    IonPage,
    IonTitle,
    IonButton,
    IonContent,
    IonToolbar,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const paymentOk: Ref<boolean|undefined> = ref(undefined);

onMounted(() => {
      store.dispatch(ActionTypes.PAYMENT_OK,undefined)
      .then((result) => {
        paymentOk.value = result;
      });
});

    return {
      router,
      paymentOk,
    };
  },
});
