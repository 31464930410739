
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
  IonInput,
  IonList,
  IonItem,
  IonText,
} from "@ionic/vue";
import { defineComponent, onMounted, ref, Ref } from "vue";
import { useRouter } from "vue-router";
import { logInOutline} from "ionicons/icons";
import { useStore, ActionTypes } from "@/store";
import { useErrorBox } from "@/components/errorBox";

export default defineComponent({
  name: "ResetWithToken",
  data() {
    return {
      loginInfo: { password: "", password2: "" }
    };
  },
  components: {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonList,
    IonIcon,
    IonItem,
    IonPage,
    IonTitle,
    IonToolbar,
    IonInput,
    IonText,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const {showError} = useErrorBox();
    const processing: Ref<boolean> = ref(false);
    const token: Ref<string> = ref("");
    const userName: Ref<string> = ref("");

    onMounted(() => {
      const parameters = router.currentRoute.value.query;
      if(parameters.token){
        token.value = parameters.token.toString();
      }
      if(parameters.user){
        userName.value = parameters.user.toString();
      }
    });

    const resetNow = async (p: any) => {
      try {
        if(p.password != p.password2){
          showError("Tarkista salasanat", true);
          return;
        }
        if(p.password.length < 6 ){
          showError("Salasana on liian lyhyt", true);
          return;
        }
        if(token.value.length < 6 ){
          showError("Tunniste tarvitaan", true);
          return;
        }

        await store.dispatch(ActionTypes.RESET_PASSWORD_WITH_TOKEN, {
          password: p.password,
          token: token.value
        });
        router.replace("/main/home");
      } catch (err) {
        showError(err as string, true);
      }
    };

    return {
      router,
      resetNow,
      logInOutline,
      token,userName,
      processing,
    };
  },
});
