import { RemovableRef, useLocalStorage } from "@vueuse/core";
import { AxiosResponse } from "axios";
import { defineStore } from "pinia";
import { getNow, prettyResponse } from ".";
import { createGuid } from "@/common/utils";
import { MessageDto } from "@/models/core/Messaging";

export class HistoryItem {
  time: Date = new Date();
  notes = "";
}

interface UserStoreState {
  srConnectionId: string|undefined;
  browserId: RemovableRef<string>;
}

export const useUserStore = defineStore('user', {
    state: (): UserStoreState => ({
      srConnectionId: undefined as string|undefined,
      browserId: useLocalStorage("browserId",createGuid())
    }),
  
    getters: {
    }, 
  
    actions: {
      async getHistoryItems(userId: number) {
        try {
            const params = new URLSearchParams([['id', userId.toString()]]);
            const r = await getNow(`UserProfile/ProfileHistory`, params) as AxiosResponse<Array<HistoryItem>>;
            return r.data;
        } catch (reason) {
            throw (prettyResponse(reason,undefined));
        }
      },



      // Inform server about window visibility change
      async visibilityChanged(hidden: boolean) {
        const params = new URLSearchParams([
          ['hidden', hidden.toString()],
          ['browserId',this.browserId]]);

        if(this.srConnectionId) 
          params.append("connectionId", this.srConnectionId);

        // debugger;
        try {
            const r = await getNow(`UserProfile/UpdateVisibility`, params) as AxiosResponse<Array<HistoryItem>>;
            return r.data;
        } catch (reason) {
          // not important to show error
          console.log("Window state change error", hidden, reason);
        }
      },

      async srConnectionOpened(connection: signalR.HubConnection) {
        this.srConnectionId = connection.connectionId ?? undefined;
      }, 

      async srConnectionClosed(error: Error|undefined) {
        console.log("SignalR connection closed",error);
        this.srConnectionId = undefined;
      },

      async srConnectionError(error: any) {
        console.error("SignalR connection error",error);
        this.srConnectionId = undefined;
      },

      async getMessages(all: boolean, limit: number) {
        const params = new URLSearchParams([
          ['all', all.toString()],
          ['limit',limit.toString()]]);

          const r = await getNow(`UserProfile/Messages`, params) as AxiosResponse<Array<MessageDto>>;
          return r.data;
      },

      async getMessage(id: string) {
          const r = await getNow(`UserProfile/Message/${id}`,undefined) as AxiosResponse<MessageDto>;
          return r.data;
      },

    }
  })