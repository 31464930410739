
export class TicketChangeModel
{
    changeTime: Date;
    newState: string;
    comments: string;
    addedCredit: number;
    constructor () {
        this.addedCredit = 0;
        this.changeTime = new Date();
        this.comments = "";
        this.newState = "";
    }
}

export class TicketInfo 
{
    action: string| undefined;
    active: boolean| undefined;
    boughtFrom: string | undefined;
    categoryId: string | undefined;
    changes: Array<TicketChangeModel>;
    comments: string | undefined;
    credit: number | undefined;
    editTime: Date;
    eventTemplate: any;
    eventTemplateId: string | undefined;
    familyMember: any;
    from: Date | undefined;
    id: string;
    issued: Date | undefined;
    memberId: string | undefined;
    price: number | undefined;
    reserved: false | undefined;
    status: string | undefined;
    ticketId: string;
    titleString: string | undefined;
    to: Date | undefined;
    visits: Array<Date>;
    organizationId: string | undefined;
    firstTerminateDate: Date | undefined;
    invoicedToDate: Date | undefined;
    discontinue: Date | undefined;
    pauseFrom: Date | undefined;
    pauseTo: Date | undefined;
    discountPercentage: number | undefined;
    discountTo: Date | undefined;
    finalPrice: number | undefined;

    constructor() {
        this.changes = [];
        this.visits = [];
        this.editTime = new Date();
        this.ticketId = "";
        this.id = "";
    }

    fromResponse = (from: TicketInfo) => {
        this.action = from.action;
        this.active = from.active;
        this.boughtFrom = from.boughtFrom;
        this.categoryId = from.categoryId;
        this.changes = from.changes;
        this.comments = from.comments;
        this.credit = from.credit;
        this.editTime = new Date(from.editTime);
        this.eventTemplate = from.eventTemplate;
        this.eventTemplateId = from.eventTemplateId;
        this.familyMember = from.familyMember;
        if(from.from){ this.from = new Date(from.from);}
        this.id = from.id;
        if(from.issued){ this.issued = new Date(from.issued);}
        this.memberId = from.memberId;
        this.price = from.price;
        this.reserved = from.reserved;
        this.status = from.status;
        this.ticketId = from.ticketId;
        this.titleString = from.titleString;
        if(from.to){ this.to = new Date(from.to);}
        this.visits = from.visits;
        this.organizationId = from.organizationId;
        this.discountPercentage = from.discountPercentage;
        this.finalPrice = from.finalPrice;
        if(from.firstTerminateDate){ this.firstTerminateDate = new Date(from.firstTerminateDate);}
        if(from.invoicedToDate){ this.invoicedToDate = new Date(from.invoicedToDate);}
        if(from.discontinue){ this.discontinue = new Date(from.discontinue);}
        if(from.pauseFrom){ this.pauseFrom = new Date(from.pauseFrom);}
        if(from.pauseTo){ this.pauseTo = new Date(from.pauseTo);}
        if(from.discountTo){ this.discountTo = new Date(from.discountTo);}
        return this;
    }
}