
import { IonIcon, IonLabel, IonItem,IonAvatar, IonList,  IonContent, IonHeader, IonTitle, modalController } from '@ionic/vue';
import { computed, defineComponent } from 'vue';
import { personCircleOutline, personAddOutline } from 'ionicons/icons';
import { useStore, ActionTypes } from '../store'
import { OrganizationInfo } from '../models/Organization';
export default defineComponent({
  name: 'SelectOrganization',
  props: {
    title: { type: String, default: 'Super Modal' },
  },
  data() {
    return {
      content: 'Content',
    }
  },
  components: {IonIcon,IonLabel,IonItem,IonAvatar,IonList, IonContent, IonHeader, IonTitle},
  
  setup(context,{emit}) {

    const store = useStore();
    const organizations = computed(() => store.getters.userInfo?.organizations);

    const selectOrganization = async (organization: OrganizationInfo) => {
      if(organization){
        store.dispatch(ActionTypes.SELECT_ORG,organization);
      }
         await modalController.dismiss();
         emit('selected-organization', organization);
    };

      return {
        selectOrganization,
        organizations,
        personCircleOutline,
        personAddOutline
      }
  }
});
