import { EventTemplate } from "@/models/EventTemplate";
import { LocationViewModel } from "@/models/LocationViewModel";
import { NewVisitModel } from "@/models/NewVisitModel";


export class PublicProfileViewModel
{
    publicProfileId: string;
    name: string;
    description: string;
    isActive: boolean;

    constructor() {
        this.publicProfileId = "";
        this.name = "";
        this.description = "";
        this.isActive = false;
    }
}

export class EventInstanceViewModel 
{
    id: string;
    title: string;
    description: string | undefined;
    participants: number;
    beginTime: Date ;
    endTime: Date | undefined;
    visits: number;
    reservations: number;
    organizationId: string | undefined;

    location: LocationViewModel | undefined;
    eventTemplate: EventTemplate | undefined;

   newVisit: NewVisitModel | undefined;
   spaceAvailable: boolean | undefined;
   comments: string;

   publicProfiles: Array<PublicProfileViewModel>;

   constructor() {
        this.id = "";
        this.title = "";
        this.participants = 0;
        this.visits = 0;
        this.reservations = 0;
        this.beginTime = new Date(2000,1,1);
        this.publicProfiles = [];
        this.comments = "";
    }

    fromResponse = (from: EventInstanceViewModel) => {
        this.id = from.id;
        this.title = from.title;
        this.description = from.description;
        this.participants = from.participants;
        this.beginTime = new Date(from.beginTime);
        if(from.endTime){ this.endTime = new Date(from.endTime);}
        this.visits = from.visits;
        this.reservations = from.reservations;
        this.organizationId = from.organizationId;
        this.location = from.location;
        this.eventTemplate = from.eventTemplate;
        this.newVisit = from.newVisit;
        this.spaceAvailable = from.spaceAvailable;
        this.publicProfiles = from.publicProfiles;
        this.comments = from.comments;
        return this;
    }
}

export class EventInstanceUpdated 
{
    id: string;
    visits: number;
    reservations: number;
    comments: string | undefined;

   constructor() {
        this.id = "";
        this.visits = 0;
        this.reservations = 0;
    }

    fromResponse = (from: EventInstanceUpdated) => {
        this.id = from.id;
        this.visits = from.visits;
        this.reservations = from.reservations;
        this.comments = from.comments;
        return this;
    }
}


