import { toastController } from '@ionic/vue';
export function useToast () {

    const showToast = async (head: string, message: string, timeOut = 20000, cssClass = 'defaultAlert') => {

      const alert = await toastController
        .create({
          header: head,
          cssClass: cssClass,
          message: message,
          duration: 5000,
          buttons: [{text:"OK", cssClass: cssClass + 'Ok'}],
        });
        await alert.present();

        const closeTimeout = setTimeout(() => alert.dismiss(), timeOut);
        alert.onDidDismiss().then(_i=>{ if(closeTimeout){ clearTimeout(closeTimeout);}});
      
        return alert;
    };

  return {
    showToast
  }
}