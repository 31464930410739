

import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonItem,
  IonMenuButton,
  IonRefresher,
  IonRefresherContent,
  IonItemSliding,
  IonToggle,
  IonItemOptions,
  IonButton,
  IonIcon,
} from "@ionic/vue";

import {
  computed,
  defineComponent,
  onMounted,
  Ref,
  ref,
  watchEffect,
} from "vue";
import {
  calendarOutline,
  peopleOutline,
  repeatOutline,
  ellipsisHorizontalOutline,
  star, starOutline,
  trash,
  pencil,
} from "ionicons/icons";
import { useRouter } from "vue-router";
import { useStore, ActionTypes } from "../store";
import { useErrorBox } from "@/components/errorBox";
import { InfoPageExtendedModel } from "@/models/InfoPageModel";

export default defineComponent({
  name: "TraniningPrograms",
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonItem,
    IonMenuButton,
    IonRefresher,
    IonRefresherContent,
    IonItemSliding,
    IonToggle,
    // IonLabel,
    IonItemOptions,
    IonButton,
    IonIcon,
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const { showError } = useErrorBox();

    const pages: Ref<Array<InfoPageExtendedModel>> = ref([]);
    const processing: Ref<boolean> = ref(true);
    let fetching = false;

    const hasRoleInstructor = computed(() => {
      return (
        store.getters.userInfo.roles?.indexOf("Instructor") >= 0 ||
        store.getters.userInfo.roles?.indexOf("Merchant") >= 0 ||
        store.getters.userInfo.roles?.indexOf("Admin") >= 0
      );
    });

    const loadPages = async () => {
      if(fetching)
        return;
      processing.value = true;
      pages.value = [];
      fetching = true;
      try {
        const prefix = store.state.organization.name + "Ohjelmointi";
        const pagesResult = await store.dispatch(ActionTypes.GET_INFO_PAGES, undefined);
        pagesResult.forEach(page => {
          if(!page.code.startsWith(prefix))
            return;

          store
            .dispatch(ActionTypes.GET_INFO_PAGE_BY_ID, page.id ?? "")
            .then( i=> pages.value.push(new InfoPageExtendedModel(i)));          
        });
      }
      catch (err) {
        showError(err as string, true);
      } finally {
        processing.value = false;
        fetching = false;
      }
    };

    onMounted(() => {
      loadPages();
    });


    const refreshNow = async (item: any) => {
      await loadPages();
      item.target.complete();
    };

    watchEffect(async () => {
      if (!store.state.organization.id || store.state.organization.id === "") {
        return;
      }
      await loadPages();
    });


    return {
      router,
      calendarOutline,
      repeatOutline,
      ellipsisHorizontalOutline,
      hasRoleInstructor,
      processing,
      refreshNow,
      peopleOutline,
      star, trash,starOutline,pencil,
      pages,
    };
  },
});
