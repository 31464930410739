
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonMenuButton,
  IonText,
  loadingController,
  IonButton,
} from "@ionic/vue";
import { defineComponent, onMounted, Ref, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore, getDomain } from "../../store";
import { useErrorBox } from "@/components/errorBox";
import axios from "axios";

export default defineComponent({
  name: "DoorActionsPage",
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonMenuButton,
    IonText,
    IonButton,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const {showError} = useErrorBox();

    //const allSqlRequests: Ref<Array<SqlRequestModel>> = ref([]);

    const checkVisitsReportLink: Ref<string|undefined> = ref(undefined);
    const listVisitsReportLink: Ref<string|undefined> = ref(undefined);
    const listAccessControlCardsLink: Ref<string|undefined> = ref(undefined);

    const loading = async () => {
      const loading = await loadingController
          .create({
            cssClass: 'my-custom-class',
            message: 'Hetki...',
            duration: undefined,
          });

      await loading.present();
      return loading;
    }   

    onMounted(() => {
      // store.dispatch(ActionTypes.LIST_SQL_REQUESTS, undefined)
      // .then((result) => {
      //   if(result){
      //     allSqlRequests.value = result;
      //     }
      // })
      // .catch((err) => {
      //   showError(err,true);
      // });
    });

    const checkVisits = async () => {
      const l = await loading();
      try {        
        const res = await axios({
          url: getDomain() + '/DoorUse/CheckVisits',
          method: 'GET',
          responseType: 'arraybuffer',
          headers: {Authorization: 'Bearer ' + store.state.user.authToken},
        });

        const FILE = window.URL.createObjectURL(new Blob([res.data]));                     
        checkVisitsReportLink.value = FILE;
      } catch (err) {
        await showError(err as string, true);
      } finally {
        l.dismiss();      
      }
    }

    const listVisits = async () => {
      const l = await loading();
      try {        
        const res = await axios({
          url: getDomain() + '/DoorUse/ListVisits',
          method: 'GET',
          responseType: 'arraybuffer',
          headers: {Authorization: 'Bearer ' + store.state.user.authToken},
        });

        const FILE = window.URL.createObjectURL(new Blob([res.data]));                     
        listVisitsReportLink.value = FILE;
      } catch (err) {
        await showError(err as string, true);
      } finally {
        l.dismiss();      
      }
    }

    const listAccessControlCards = async () => {
      const l = await loading();
      try {        
        const res = await axios({
          url: getDomain() + '/DoorUse/ListDoorAccessControlCards',
          method: 'GET',
          responseType: 'arraybuffer',
          headers: {Authorization: 'Bearer ' + store.state.user.authToken},
        });

        const FILE = window.URL.createObjectURL(new Blob([res.data]));                     
        listVisitsReportLink.value = FILE;
      } catch (err) {
        await showError(err as string, true);
      } finally {
        l.dismiss();      
      }
    }

    const getDoorActions = async () => {
      const l = await loading();
      try {        
        await axios({
          url: getDomain() + '/DoorUse/GetDoorActions',
          method: 'GET',
          headers: {Authorization: 'Bearer ' + store.state.user.authToken},
        });
      } catch (err) {
        await showError(err as string, true);
      } finally {
        l.dismiss();      
      }
    }

    const getAccessControlCards = async () => {
      const l = await loading();
      try {        
        await axios({
          url: getDomain() + '/DoorUse/GetAccessControlCards',
          method: 'GET',
          headers: {Authorization: 'Bearer ' + store.state.user.authToken},
        });
      } catch (err) {
        await showError(err as string, true);
      } finally {
        l.dismiss();      
      }
    }


    return {
      router,
      checkVisits,listVisits,
      getDoorActions,getAccessControlCards,
      checkVisitsReportLink,listVisitsReportLink,
      listAccessControlCards,listAccessControlCardsLink,
    };
  },
});
