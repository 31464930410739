
import { defineComponent,computed } from 'vue';
import { 
  IonIcon, 
  IonLabel, 
  IonPage,
  IonTabBar, 
  IonTabButton, 
  IonTabs,
  IonRouterOutlet,
  modalController
} from '@ionic/vue';
import { calendarOutline, personCircle, logInOutline, informationOutline, locationOutline, bodyOutline, bookOutline } from 'ionicons/icons';
import { useStore } from '@/store';
import SelectOrganization from '@/components/SelectOrganization.vue';
import { useRouter } from 'vue-router';

export default defineComponent({
name: 'MainPage',
  components: { IonIcon, IonLabel, IonPage, IonTabBar, IonTabButton, IonTabs, IonRouterOutlet },
  setup() {
        
    const store = useStore();
    const router = useRouter();

    const beforeTabChange = () => {
      // do something before tab change
    }
    const afterTabChange = () => {
      // do something after tab change
    }

      const location = computed(() => store.getters.organisation);
      const fullProfile = computed(() => store.getters.fullProfile);

      const showOrganizarionSelect = async () => {
        const modal = await modalController
        .create({
          component: SelectOrganization,
          cssClass: 'my-custom-class',
          componentProps: {
            title: 'New Title'
          },
        });
      return modal.present();
    }

      const isActive = (url: string) => {
        // console.log(router.currentRoute.value.path);
        // console.log(url);
        const result = router.currentRoute.value.path === url;
        // console.log(result);
        return result;
    }

    return {
      calendarOutline,bookOutline,
      personCircle, logInOutline, informationOutline,locationOutline, bodyOutline,
      beforeTabChange,
      afterTabChange,
      fullProfile,
      location,
      showOrganizarionSelect,
      isActive
    }
  }
});
