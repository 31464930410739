
import {
  IonButtons,
  IonContent,
  IonHeader,
  alertController,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonMenuButton,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonIcon,
  loadingController,
} from "@ionic/vue";
import { defineComponent, onMounted, Ref, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore, ActionTypes } from "@/store";
import { useErrorBox } from "@/components/errorBox";
import { addOutline,pencil,trashOutline } from "ionicons/icons";
import { InfoPageListModel } from "@/models/InfoPageModel";
export default defineComponent({
  name: "ListUserCategories",
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonIcon,
    IonButton,
    IonMenuButton,
    IonGrid,
    IonRow,
    IonCol,
    IonText,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const {showError} = useErrorBox();

    const allInfoPages: Ref<Array<InfoPageListModel>> = ref([]);

    const loading = async () => {
      const loading = await loadingController
          .create({
            cssClass: 'my-custom-class',
            message: 'Hetki...',
            duration: undefined,
          });

      await loading.present();
      return loading;
    }    

    onMounted(() => {
      store.dispatch(ActionTypes.GET_INFO_PAGES, undefined)
      .then((result) => {
        if(result){
          allInfoPages.value = result;
          }
      })
      .catch((err) => {
        showError(err,true);
      });
    });

    const showRemoveDialog = async (infoPage: InfoPageListModel) => {

      const alert = await alertController
        .create({
          cssClass: 'my-custom-class',
          header: 'Poistetaanko sivu \'' + infoPage.title + '\'?',
          // subHeader: 'Subtitle',
          message: 'Sivu poistuu pysyvästi.',
          buttons: [{
            text:'Poista',
            cssClass: 'danger',
            role:'remove'
          },{
            text:'Peru',
            role: 'cancel',
            cssClass: 'secondary',
          }],
        });
      await alert.present();

      const {role} = await alert.onDidDismiss();
      if(role !== "remove")
        return;

      const l = await loading();
      try {
        // await store.dispatch(ActionTypes.REMOVE_USER_CATEGORY, category.id);

        // const index2 = allUserCategories.value.findIndex(i=>i.id == category.id);
        // if (index2 > -1)
        //       allUserCategories.value.splice(index2, 1);
      } catch (err){
        showError(err as string,true); 
      } finally {
        l.dismiss();
      }
    }

    return {
      router,
      allInfoPages,
      showRemoveDialog,
      addOutline,pencil,trashOutline,
    };
  },
});
