

import { GestureDetail } from "@ionic/vue";
import { UnsubscribeFunction } from "emittery";
import { defineComponent,  onMounted, onUnmounted, Ref, ref } from "vue";
import { emitter } from "@/components/useEvents";

export default defineComponent({
  name: 'AddDroppable',
  props: {
    class: { type: String, required: false},
    destination: { required: true},
    destinationData: { required: false}
  },
  emits:['onElementDropped'],
  setup(props,context) {

    const root: Ref<HTMLElement|undefined>  = ref(undefined);
    const classString: Ref<string>  = ref("");

    let emitterDismiss: UnsubscribeFunction|undefined = undefined;  
    let emitterDismiss2: UnsubscribeFunction|undefined = undefined;  

    onMounted(async () => {
      if(props.class)
        classString.value = props.class;
      emitterDismiss = emitter.on('onElementDropping',onElementDropped);
      emitterDismiss2 = emitter.on('onElementMoving',onElementMoving);
      if(!root.value) return;
      root.value.classList.add('drop-idle');
    });

    onUnmounted(async ()=>{
      if(emitterDismiss)
        emitterDismiss();
      if(emitterDismiss2)
        emitterDismiss2();
    });

    const isInsideDroppableArea = (x: number, y: number) => {
      if(!root.value) return false;      
      const droppableArea = root.value.getBoundingClientRect();
      if (x < droppableArea.left || x >= droppableArea.right) {
        return false;
      }
      if (y < droppableArea.top || y >= droppableArea.bottom) {
        return false;
      }
      return true;
    }

    const onElementMoving = async (eventData: any) => {     
      const ev: GestureDetail = eventData.ev;
      const destination: string = eventData.destination;

      if(destination != props.destination) return;
      if(!root.value) return;

      if (isInsideDroppableArea(ev.currentX, ev.currentY)) {        
        root.value.classList.remove('drop-idle');
        root.value.classList.remove('drop-fail');
        root.value.classList.add('drop-success');
      } else {
        root.value.classList.remove('drop-idle');
        root.value.classList.remove('drop-success');
        root.value.classList.add('drop-fail');
      }

    };

    const onElementDropped = async (eventData: any) => {
        // debugger;
        const ev: GestureDetail = eventData.ev;
        const data: any = eventData.data;
        const destination: string = eventData.destination;
        if(destination != props.destination)
          return;

        if(!root.value) return;

        root.value.classList.add('drop-idle');
        root.value.classList.remove('drop-fail');
        root.value.classList.remove('drop-success');

        if (isInsideDroppableArea(ev.currentX, ev.currentY)) {
          context.emit('onElementDropped',{ dropData:data, destinationData:props.destinationData });
        }

      };
    return {
      root,
      classString
    };
  }
});
