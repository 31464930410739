
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
  IonInput,
  IonItem,
  IonButton,
  IonRow,
  IonCol,
  IonText,
} from "@ionic/vue";
import { defineComponent, onMounted, Ref, ref } from "vue";
import { useRouter } from "vue-router";
import { saveOutline } from "ionicons/icons";
import { useStore,  ActionTypes } from "@/store";
import { useErrorBox } from "@/components/errorBox";
import { InfoPageModel } from "@/models/InfoPageModel";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default defineComponent({
  name: "EditUserCategory",
  data() {
    return {
      processing: false,
    };
  },
  components: {
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonInput,
    IonItem,
    IonButton,
    IonRow,
    IonCol,
    IonText,
    IonIcon,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const {showError} = useErrorBox();
    const infoPage: Ref<InfoPageModel|undefined> = ref(undefined);

    const editor = ClassicEditor;
    const editorConfig = {};

    onMounted(() => {
      const id = router.currentRoute.value.params["id"].toString();

      if(!id || id === ""){
        infoPage.value = {html: "", id: "00000000-0000-0000-0000-000000000000", code: "", title: ""}
        return;
      }

      store.dispatch(ActionTypes.GET_INFO_PAGE_BY_ID, id)
        .then((result) => {
          infoPage.value = result;
        })
        .catch((err) => {
          showError(err,true);
      });
    });
    

    const save = () => {
      if(!infoPage.value)
        return;

      store.dispatch(ActionTypes.UPDATE_INFO_PAGE, infoPage.value)
        .then((result) => {
          infoPage.value = result;
        })
        .catch((err) => {
          showError(err,true);
      });
    }

    return {
      save,
      infoPage,
      editor,editorConfig,
      saveOutline,
    };
  }
});
