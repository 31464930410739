import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_datetime_button = _resolveComponent("ion-datetime-button")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_datetime = _resolveComponent("ion-datetime")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_ion_refresher_content = _resolveComponent("ion-refresher-content")!
  const _component_ion_refresher = _resolveComponent("ion-refresher")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_menu_button, { menu: "main-menu" })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("Myynti " + _toDisplayString(_ctx.organization.name), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_buttons, { slot: "end" }, {
                default: _withCtx(() => [
                  (_ctx.hasRoleAccounting)
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 0,
                        onClick: _ctx.toFixingPage
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Lisäämään korjaus")
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_ion_datetime_button, {
                    color: "primary",
                    datetime: "datetime"
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_modal, { "keep-contents-mounted": true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_datetime, {
            id: "datetime",
            presentation: "date",
            "first-day-of-week": 1,
            value: _ctx.date,
            onIonChange: _ctx.selectDate
          }, null, 8, ["value", "onIonChange"])
        ]),
        _: 1
      }),
      (_ctx.hasRoleInstructor)
        ? (_openBlock(), _createBlock(_component_ion_content, {
            key: 0,
            class: "ion-padding"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_refresher, {
                slot: "fixed",
                onIonRefresh: _cache[0] || (_cache[0] = ($event: any) => (_ctx.refreshNow($event)))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_refresher_content)
                ]),
                _: 1
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cartList, (cart) => {
                return (_openBlock(), _createBlock(_component_ion_item, {
                  key: cart.id,
                  class: "ion-no-padding"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_col, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_button, {
                          onClick: ($event: any) => (_ctx.router.push('/shoppingCart/'+cart.id))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(cart.number), 1)
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_ion_col, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(cart.type), 1)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_ion_col, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.momentjs(cart.created).format('H:hh')), 1)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_ion_col, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(cart.total?.toFixed(2)) + "€", 1)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_ion_col, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(cart.lines), 1)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_ion_col, null, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(cart.payments, (payment) => {
                          return (_openBlock(), _createBlock(_component_ion_text, {
                            key: payment.accountId
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(payment.account) + " ", 1)
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_ion_col, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_button, {
                          color: "secondary",
                          onClick: ($event: any) => (_ctx.printReceipt(cart))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_icon, { icon: _ctx.printOutline }, null, 8, ["icon"])
                          ]),
                          _: 2
                        }, 1032, ["onClick"]),
                        (_ctx.email)
                          ? (_openBlock(), _createBlock(_component_ion_button, {
                              key: 0,
                              color: "secondary",
                              onClick: ($event: any) => (_ctx.sendEmail(cart))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, { icon: _ctx.sendOutline }, null, 8, ["icon"])
                              ]),
                              _: 2
                            }, 1032, ["onClick"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024))
              }), 128)),
              _createVNode(_component_ion_input, {
                fill: "outline",
                modelValue: _ctx.email,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.email) = $event)),
                type: "email",
                label: "Sähköposti"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}