
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
  IonInput,
  IonList,
  IonItem,
  IonText,
} from "@ionic/vue";
import { defineComponent, ref, Ref } from "vue";
import { useRouter } from "vue-router";
import { logInOutline} from "ionicons/icons";
import { useStore, ActionTypes } from "@/store";
import { useErrorBox } from "@/components/errorBox";

export default defineComponent({
  name: "ResetPassword",
  components: {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonList,
    IonIcon,
    IonItem,
    IonPage,
    IonTitle,
    IonToolbar,
    IonInput,
    IonText,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const {showError} = useErrorBox();
    const processing: Ref<boolean> = ref(false);
    const email: Ref<string> = ref("");

    const resetNow = (emailStr: string) => {
      processing.value = true;
        if(emailStr.length<4){
          showError("Anna käyttäjätunnus tai sähköpostisi", true);
          processing.value = false;
          return;
        }

        store.dispatch(ActionTypes.REQUEST_PASSWORD_RESET_TOKEN, emailStr)
        .then(()=>{ showError("Ohjeet salasanan asettamiseksi lähetettiin sähköpostiisi.", false);})
        .catch((err)=> { showError(err, true);})
        .finally(()=> { processing.value = false;});
        
    };

    return {
      router,
      resetNow,
      logInOutline,
      email,
      processing
    };
  },
});
