
export class ReservationViewModel {
    id: string;
    eventInstanceId: string | undefined;
    ticketId: string | undefined;
    created: Date | undefined;
    quantity: number;
    userId: number;
    familyMemberId: string | undefined;
 
    // Read only fields
    eventInstanceBeginTime: Date | undefined;
    eventInstanceTitle: string | undefined;
    eventInstanceTemplateId: string | undefined;
    memberName: string;
  
    ticketBased: boolean;

    constructor() {
        this.quantity = 0;
        this.userId = 0;
        this.id = "";
        this.memberName = "";
        this.ticketBased = false;
    }

    fromResponse = (from: ReservationViewModel) => {
        
        this.id = from.id;
        this.eventInstanceId = from.eventInstanceId;
        this.ticketId = from.ticketId;
        this.created = new Date(from.created as any);
        this.quantity = from.quantity;
        this.userId = from.userId;
        this.memberName = from.memberName;
        this.familyMemberId = from.familyMemberId;

        this.eventInstanceBeginTime = new Date(from.eventInstanceBeginTime as any);
        this.eventInstanceTitle = from.eventInstanceTitle;
        this.eventInstanceTemplateId = from.eventInstanceTemplateId;
        this.ticketBased = false;
        return this;
    }

}

export class NewReservationRequest
{
    eventInstanceId: string;
    quantity: number;
    userId: number;
    familyMemberId: string | undefined;

    constructor(){
        this.quantity = 0;
        this.userId = 0;
        this.eventInstanceId = "";
    }
}
