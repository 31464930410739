
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonMenuButton,
  IonText,
  IonButton,
  loadingController,
} from "@ionic/vue";
import { defineComponent, onMounted, Ref, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore, ActionTypes, getDomain } from "@/store";
import { useErrorBox } from "@/components/errorBox";
import { SqlRequestModel } from "@/models/SqlRequestModel";
import axios from "axios";

export default defineComponent({
  name: "ListSqlRequests",
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton,
    IonMenuButton,
    IonText,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const { showError } = useErrorBox();

    const allSqlRequests: Ref<Array<SqlRequestModel>> = ref([]);
    const saveReportLink: Ref<string | undefined> = ref(undefined);
    const reportName: Ref<string | undefined> = ref(undefined);

    const loading = async () => {
      const loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: 'Hetki...',
          duration: undefined,
        });

      await loading.present();
      return loading;
    }

    onMounted(async () => {
      try {
        const result = await store.dispatch(ActionTypes.LIST_SQL_REQUESTS, undefined);
        if (result) {
          allSqlRequests.value = result.sort((a, b) => (a.title ?? '').localeCompare(b.title ?? ''));
        }
      } catch (err) {
        showError(err as string, true);
      }
    });

    const downloadFile = async (r: SqlRequestModel) => {
      saveReportLink.value = undefined;

      const l = await loading();
      try {
        const res = await axios({
          url: getDomain() + '/SqlRequest/Run?id=' + r.id,
          method: 'GET',
          responseType: 'arraybuffer',
          headers: { Authorization: 'Bearer ' + store.state.user.authToken },
        });
        
        const FILE = window.URL.createObjectURL(new Blob([res.data]));
        saveReportLink.value = FILE;
        reportName.value = r.title;
      } catch (err) {
        showError(err as string, true);
      } finally {
        l.dismiss();
      }
    }

    return {
      router,
      allSqlRequests,
      downloadFile,
      saveReportLink, reportName,
    };
  },
});
