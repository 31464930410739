import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_tab_button = _resolveComponent("ion-tab-button")!
  const _component_ion_tab_bar = _resolveComponent("ion-tab-bar")!
  const _component_ion_tabs = _resolveComponent("ion-tabs")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_tabs, {
        onIonTabsWillChange: _ctx.beforeTabChange,
        onIonTabsDidChange: _ctx.afterTabChange
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_router_outlet),
          _createVNode(_component_ion_tab_bar, { slot: "bottom" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_tab_button, {
                class: "condencedTab",
                tab: "home",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.replace('/main/home'))),
                selected: _ctx.isActive('/main/home')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.informationOutline }, null, 8, ["icon"]),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Etusivu")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["selected"]),
              _createVNode(_component_ion_tab_button, {
                class: "condencedTab",
                onClick: _ctx.showOrganizarionSelect,
                selected: false
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.locationOutline }, null, 8, ["icon"]),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.location?.name), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_ion_tab_button, {
                class: "condencedTab",
                tab: "calendar",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.replace('/main/calendar'))),
                selected: _ctx.isActive('/main/calendar') == true
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.calendarOutline }, null, 8, ["icon"]),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Kalenteri")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["selected"]),
              _createVNode(_component_ion_tab_button, {
                class: "condencedTab",
                tab: "trainingPrograms",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.replace('/main/trainingPrograms'))),
                selected: _ctx.isActive('/main/trainingPrograms') == true
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.bookOutline }, null, 8, ["icon"]),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Ohjelmoinnit")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["selected"]),
              _createVNode(_component_ion_tab_button, {
                class: "condencedTab",
                tab: "checkin",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$router.replace('/main/checkin/'+_ctx.fullProfile?.userId))),
                selected: _ctx.isActive('/main/checkin/'+_ctx.fullProfile?.userId) == true
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.logInOutline }, null, 8, ["icon"]),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Tunnille")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["selected"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["onIonTabsWillChange", "onIonTabsDidChange"])
    ]),
    _: 1
  }))
}