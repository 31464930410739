
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
} from "@ionic/vue";
import { defineComponent, onMounted, Ref, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore, ActionTypes } from "../store";
import { TicketType } from "@/models/TicketType";
import { useErrorBox } from "@/components/errorBox";

export default defineComponent({
  name: "PurchaseView",
  data() {
    return {
      searchTerm: "",
      processing: false,
    };
  },
  components: {
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const id = router.currentRoute.value.params["id"].toString();
    const ticket: Ref<TicketType|null> = ref(null);

    const {showError} = useErrorBox();

    onMounted(async () => {

      // Id not given
      if(id === ""){
        const a = await showError("Korttia ei löytynyt!", true);
        await a.onDidDismiss();
        router.back();
        return;
      }
  
      try
      {
        const result = await store.dispatch(ActionTypes.LOAD_TICKET,id);
        ticket.value = result;

        if(!ticket.value)
          throw("Korttia ei löytynyt!"); 

      await store.dispatch(ActionTypes.ADD_TO_CART, {
        eventId: undefined,
        ticketId: undefined,
        ticketTypeId: ticket.value.id,
        comments: "",
        discount: 0,
        id: undefined,
        name: ticket.value?.name,
        price: ticket.value?.price,
        quantity: 1,
        memberId: undefined,
      });

      router.replace('/checkout1/');

      }
      catch (err)  {
        console.log(err);
        const b = await showError((err as any).message, true);
        await b.onDidDismiss();
        router.back();
      }
    });
   
    const addToCart = (checkOut: boolean) => {
      if( ticket.value === null || ticket.value.id === null)
        return;



      if(checkOut){
        router.replace('/checkout/');
      } else {
        router.back();
      }
    };

    return {
      router,
      ticket,
      addToCart
    };
  },
});
