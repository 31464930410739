import { useErrorBox } from '@/components/errorBox';
import { ActionTypes } from '@/store';
import { loadingController } from '@ionic/vue';
import { ref, Ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export function useCheckoutUtils () {

  const processing: Ref<boolean> = ref(false);    
  const router = useRouter();
  const store = useStore();
  const {showError} = useErrorBox();

  const loading = async () => {
    const loading = await loadingController
      .create({
        message: 'Hetki...',
        duration: undefined,
      });

    await loading.present();
    return loading;
  }


  const refreshCart = async () => {
      
      processing.value = true;
      const l = await loading();

      try{
        const cart = await store.dispatch(ActionTypes.REFRESH_CART,undefined);
        if(!cart?.rows || cart?.rows.length === 0) {
          await showError("Ostoskori on tyhjä.",false);
          router.replace('/');
          return false;
        }
        return true;
      } catch (reason) {

        if(reason === "unauthorised") {
          router.replace('/login/');
        } else if(reason === "empty") {
          await showError("Ostoskori on tyhjä.",false);
          router.replace('/');
        } else if(reason === "closed") {
          await showError("Ostoskoria ei enää löydy.",false);
          router.replace('/');
        } else if(reason === "Ostoskoria ei enää löydy.") {
          await showError("Ostoskoria ei enää löydy.",false);
          router.replace('/');
        }
        return false;

      } finally {
        processing.value = false;
        await l.dismiss();
      }
  }

  return {
    refreshCart, 
    processing,
  }
}