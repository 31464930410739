import { JsTimeSpan } from "./JsTimeSpan";
import { TicketType } from "./TicketType";
import { LocationViewModel } from "./LocationViewModel";
import { EventCategoryViewModel } from "./EventCategoryViewModel";


export class EventTemplate {
    description: string | undefined;
    eventStyleClass: string | undefined;
    lastCancellation: JsTimeSpan | undefined;
    reservationBegins: JsTimeSpan | undefined;
    reservationEnds: JsTimeSpan | undefined;
    location: LocationViewModel | undefined;
    participants: number | undefined;
    templateId: string | undefined;
    title: string | undefined;
    organizationId: string | undefined;
    locationId: string | undefined;
    descriptionId: string | undefined;
    eventStyleId: string | undefined;
    isActive: boolean | undefined;
    requireReservation: boolean | undefined;
    tickets: Array<TicketType>;

    categories: Array<EventCategoryViewModel>;

    constructor() {
        this.categories = [];
        this.tickets = [];
    }
}

export class EventTemplateSimpleViewModel {
    templateId: string | undefined;
    title: string | undefined;
    ageMax: number | undefined;
    ageMin: number | undefined;
    partisipants: number | undefined;

    IsActive: boolean | undefined;
    RequireReservation: boolean | undefined;

    locationId: string | undefined;
    organizationId: string | undefined;
    descriptionId: string | undefined;
    eventStyleId: string | undefined;

    fromResponse2 = (from: EventTemplateListItemViewModel) => {
        this.templateId = from.templateId;
        this.title = from.title;
        this.ageMax = from.ageMax;
        this.ageMin = from.ageMin;
        this.IsActive = from.IsActive;
        this.RequireReservation = from.RequireReservation;
        this.locationId = from.locationId;
        this.organizationId = from.organizationId;
        this.descriptionId = from.descriptionId;
        this.eventStyleId = from.eventStyleId;
    }

}


export class EventTemplateListItemViewModel extends EventTemplateSimpleViewModel {

    ticketTypes: Array<string>;
    nexTime: Date | undefined;
    freeSlots: number | undefined;
    constructor() {
        super();
        this.ticketTypes = [];
    }

    fromResponse = (from: EventTemplateListItemViewModel) => {
        this.fromResponse2(from);
        this.ticketTypes = from.ticketTypes;
        this.freeSlots = from.freeSlots;
        if(from.nexTime){ this.nexTime = new Date(from.nexTime);}
        return this;
    }
}


