
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonInput,
  IonItem,
  IonButton,
  IonRow,
  IonCol,
  IonText,
  IonTextarea,
  IonCheckbox,
  IonSelect,
  IonSelectOption,
} from "@ionic/vue";
import { defineComponent, onMounted, Ref, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore, ActionTypes, } from "../store";
import { ProductViewModel, VatGroupViewModel } from "@/models/ProductViewModel";
import { useErrorBox } from "@/components/errorBox";
import { EventCategoryViewModel } from "@/models/EventCategoryViewModel";
import { FinAccountModel } from "@/models/ShoppingCart";
import { OrganizationInfo } from "@/models/Organization";
import { useCalendarStore } from "@/store/calendarStore";
import { useFinancialStore } from "@/store/financialStore";
export default defineComponent({
  name: "EditProduct",
  data() {
    return {
      processing: false,
    };
  },
  components: {
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonInput,
    IonItem,
    IonButton,
    IonRow,
    IonCol,
    IonText,
    IonTextarea,
    IonCheckbox,
    IonSelect,
    IonSelectOption,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const calendarStore = useCalendarStore();
    const financialStore = useFinancialStore();
    
    const {showError} = useErrorBox();
    const product: Ref<ProductViewModel|undefined> = ref(undefined);
    const categories: Ref<Array<EventCategoryViewModel>> = ref([]);
    const accounts: Ref<Array<FinAccountModel>> = ref([]);
    const organizations: Ref<Array<OrganizationInfo>> = ref([]);
    const vatGroups: Ref<Array<VatGroupViewModel>> = ref([]);

    onMounted( async() => {
      const id = router.currentRoute.value.params["id"].toString();

      try {

        const c = await calendarStore.getCategories(store.state.organization.id);
        if(c) categories.value = c.filter(i=>i.active);            
        
        accounts.value = await financialStore.getFinAccounts();        
        organizations.value = await store.dispatch(ActionTypes.GET_ORGANIZATIONS, undefined);
        vatGroups.value = await financialStore.getVatGroups();
        product.value = await store.dispatch(ActionTypes.GET_PRODUCT, id);

      } catch (err) {
        showError(err as string,true);
      }
    });
    

    const saveProduct = () => {
      if(!product.value)
        return;

      store.dispatch(ActionTypes.UPDATE_PRODUCT, product.value)
        .then((result) => {
          product.value = result;
        })
        .catch((err) => {
          showError(err,true);
      });
    }

    // const scanBarcode = () => {

    //   const barcodeScanner = new BarcodeScanner();
    //   barcodeScanner.scan().then(barcodeData => {
    //     if(product.value){
    //       product.value.barcode = barcodeData.text;
    //     }
    //     console.log('Barcode data', barcodeData);
    //   }).catch((err) => {
    //     console.log('Error', err);
    //   });
      
    // }

    return {
      saveProduct,
      product,
      categories,
      accounts,
      organizations,
      vatGroups,
      // scanBarcode,
    };
  }
});
