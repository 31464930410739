
import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
  IonInput,
  IonItem,
  IonList,
  IonGrid,
  IonRow,
  IonCol,
  IonFab,
  IonFabButton,
  loadingController,
} from "@ionic/vue";
import { computed, defineComponent, onMounted, Ref, ref } from "vue";
import { useRouter } from "vue-router";
import { personOutline, personAddOutline, logInOutline, cartOutline,} from "ionicons/icons";
import { useStore, ActionTypes, UserLogin, useSessionObject } from "@/store";
import { useErrorBox } from "@/components/errorBox";

export default defineComponent({
  name: "LoginView",
  data() {
    return {
      loginInfo: { username: "", password: "" }
    };
  },
  components: {
    // IonBackButton,
    IonButton,
    // IonButtons,
    IonContent,
    IonHeader,
    IonList,
    IonIcon,
    IonItem,
    IonPage,
    IonTitle,
    IonToolbar,
    IonInput,
    IonGrid,
    IonRow,
    IonCol,
    IonFab,
    IonFabButton,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const session = useSessionObject();
    const cart = computed(() => store.getters.cart);

    const processing: Ref<boolean> = ref(false);
    const {showError} = useErrorBox();

    const loading = async () => {
      const loading = await loadingController
          .create({
            cssClass: 'my-custom-class',
            message: 'Hetki...',
            duration: undefined,
          });

      await loading.present();
      return loading;
    } 

    onMounted( async () => {
      await store.dispatch(ActionTypes.CHECK_AUTH, undefined);
    });

    const loginNow = async (p: UserLogin) => {
      const l = await loading();      
      try {
        processing.value = true;
        const ret = await store.dispatch(ActionTypes.LOGIN, {login:p, waitAll:true});
        processing.value = false;
        l.dismiss();

        if(ret.roles.indexOf("CheckinOnly") >= 0)
          router.replace('/cardRead');
        else if (session.enteredRoute && session.enteredRoute != "/login"){
          router.replace(session.enteredRoute);
        }
        else
          router.replace('/main/home');
          
      } catch (err) {
        processing.value = false;
        l.dismiss();

        showError(err as string, true);
      }
    };

    return {
      router,
      loginNow,
      logInOutline,personOutline,personAddOutline,cartOutline,
      processing,
      cart,
    };
  },
});
