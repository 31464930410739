
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonSearchbar,
  IonText,
  IonMenuButton,
  IonGrid,
  IonCol,
  IonRow,
  IonSelect,
  IonSelectOption,
  loadingController,
} from "@ionic/vue";
import { computed, defineComponent, onMounted, Ref, ref } from "vue";
import { useRouter } from "vue-router";
import { squareOutline, bookmarkOutline,personAddOutline, logInOutline, createOutline,person, personOutline,trashOutline,
  peopleOutline, } from "ionicons/icons";
import { ActionTypes, useStore } from "@/store";
import { UserCategoryFullInfo, UserProfileViewModel } from "@/models/UserDetailedViewModel";
import { useErrorBox } from "@/components/errorBox";

export default defineComponent({
  name: "CustomersView",
  data() {
    return {
      searchTerm: "",
      processing: false,
    };
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton,
    IonIcon,
    IonSearchbar,
    IonMenuButton,
    IonText,
    IonGrid,
    IonSelect,
    IonSelectOption,
    IonCol,
    IonRow,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const {showError} = useErrorBox();
    const customers: Ref<Array<UserProfileViewModel>> = ref([]);
    const allUserCategories: Ref<Array<UserCategoryFullInfo>> = ref([]);

    const categoryId: Ref<string|undefined> = ref(undefined);

    const hasRoleInstructor = computed(() => { 
      return store.getters.userInfo.roles?.indexOf("Instructor") >= 0 ||
             store.getters.userInfo.roles?.indexOf("Admin") >= 0 ||
             store.getters.userInfo.roles?.indexOf("Merchant") >= 0;});   

    const hasRoleMerchant = computed(() => { return store.getters.userInfo.roles?.indexOf("Merchant") >= 0;});

    const loading = async () => {
      const loading = await loadingController
          .create({
            cssClass: 'my-custom-class',
            message: 'Hetki...',
            duration: undefined,
          });

      await loading.present();
      return loading;
    }    

    const search = async (p: string) => {      
      const l = await loading();
      try {
        const result = await store.dispatch(ActionTypes.SEARCH_USERS,{search: p, categoryId: categoryId.value})
        if(result?.items){
          customers.value = result?.items;
        }
      } catch (err){
        if((err as any).message)
          showError((err as any).message as string,true); 
        else
          showError(err as string,true); 
      } finally {
        l.dismiss();
      }
    };
    
    onMounted(() => {
      store.dispatch(ActionTypes.GET_USER_CATEGORIES, undefined)
      .then((result) => {
        if(result){
          allUserCategories.value = result;
          }
      })
      .catch((err) => {
        showError(err,true);
      });
    });

    const selected = computed(() => {
      return customers.value.filter(a=>a.selected);
    })

    const removeSelected = async () => {
      if(!selected.value || selected.value.length != 1)
        return;

      const l = await loading();
      try {
        const userId = selected.value[0].userId ?? -1;
        await store.dispatch(ActionTypes.REMOVE_USER, userId);
        const index = customers.value.findIndex(i=>i.userId == userId); 
        if (index > -1) customers.value.splice(index, 1);

      } catch (err){
        if((err as any).message)
          showError((err as any).message as string,true); 
        else
          showError(err as string,true); 
      } finally {
        l.dismiss();
      }
    }

    const combineSelected = async () => {
      if(!selected.value || selected.value.length != 2)
        return;

      const l = await loading();
      try {
        const userId1 = selected.value[0].userId ?? -1;
        const userId2 = selected.value[1].userId ?? -1;
        await store.dispatch(ActionTypes.COMBINE_USER, {id1: userId1, id2: userId2});
        const index = customers.value.findIndex(i=>i.userId == userId2); 
        if (index > -1) customers.value.splice(index, 1);
      } catch (err){
        if((err as any).message)
          showError((err as any).message as string,true); 
        else
          showError(err as string,true); 
      } finally {
        l.dismiss();
      }          
    }

    return {
      categoryId,
      router,
      hasRoleInstructor,hasRoleMerchant,
      allUserCategories,
      search,
      customers,person,personOutline,createOutline,logInOutline,personAddOutline,bookmarkOutline,
      squareOutline,trashOutline,peopleOutline,
      selected,
      removeSelected,combineSelected,
    };
  },
});
