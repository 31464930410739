import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_refresher_content = _resolveComponent("ion-refresher-content")!
  const _component_ion_refresher = _resolveComponent("ion-refresher")!
  const _component_ion_item_options = _resolveComponent("ion-item-options")!
  const _component_ion_toggle = _resolveComponent("ion-toggle")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_item_sliding = _resolveComponent("ion-item-sliding")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_menu_button, { menu: "main-menu" })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("Ohjelmoinnit")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { class: "ion-padding" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_refresher, {
            slot: "fixed",
            onIonRefresh: _cache[0] || (_cache[0] = ($event: any) => (_ctx.refreshNow($event)))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_refresher_content)
            ]),
            _: 1
          }),
          (_ctx.processing)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, "ladataan"))
            : _createCommentVNode("", true),
          (!_ctx.processing && _ctx.pages.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pages, (page) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: page.id
                  }, [
                    _createVNode(_component_ion_item_sliding, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item_options, { side: "end" }),
                        _createVNode(_component_ion_item, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_toggle, {
                              justify: "start",
                              labelPlacement: "end",
                              modelValue: page.selected,
                              "onUpdate:modelValue": ($event: any) => ((page.selected) = $event)
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(page.title), 1)
                              ]),
                              _: 2
                            }, 1032, ["modelValue", "onUpdate:modelValue"]),
                            (_ctx.hasRoleInstructor)
                              ? (_openBlock(), _createBlock(_component_ion_button, {
                                  key: 0,
                                  slot: "end",
                                  color: "primary",
                                  onClick: ($event: any) => (_ctx.router.push(`/editInfoPage/${page.id}`))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_icon, { icon: _ctx.pencil }, null, 8, ["icon"]),
                                    _createTextVNode(" muokkaa")
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024),
                    (page.selected)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          innerHTML: page.html
                        }, null, 8, _hoisted_3))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}