export class EventInstance 
{
    id: string| undefined;
    name: string| undefined;
    ageMax: number | undefined;
    ageMin: number | undefined;
    partisipants: number | undefined;
    tickets: number | undefined;
    booked: number | undefined;
    nextInstance: Date | undefined;
    location: string | undefined;
    locationId: string | undefined;
    organizationId: string | undefined;
    instructors: string | undefined;
    instructorHtml: string | undefined;

    fromResponse = (from: EventInstance) => {
        this.id = from.id;
        this.name = from.name;
        this.ageMax = from.ageMax;
        this.ageMin = from.ageMin;
        this.partisipants = from.partisipants;
        this.tickets = from.tickets;
        this.booked = from.booked;
        this.nextInstance = new Date (from.nextInstance as any);
        this.location = from.location;
        this.locationId = from.locationId;
        this.organizationId = from.organizationId;
        this.instructors = from.instructors;
        this.instructorHtml = from.instructorHtml;
        return this;
    }
}

export class EventDescription 
{
    descriptionTitle: string| undefined;
    description: string| undefined;
    instances: Array<EventInstance>;

    constructor(){
        this.instances = [];
    }

    fromResponse = (from: EventDescription) => {
        this.descriptionTitle = from.descriptionTitle;
        this.description = from.description;
 
        from.instances.forEach(i=>{
            const r = (new EventInstance).fromResponse(i);
            this.instances.push(r);
        },this);
        this.instances.sort((i,j)=>(i.nextInstance ?? new Date()).getTime()-(j.nextInstance?? new Date()).getTime());

        return this;
    }
}

export class EventDescriptionSearchRules {
    organizationId: string | undefined;
    categoryId: string | undefined;
    locationId: string | undefined;
}